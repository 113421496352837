<template>
  <div class="card bg-dark bg-opacity-10">
    <div class="card-body">
      <div class="row">
        <div class="col-6 d-flex flex-column">
          <div>
            <img
              class="img-rank"
              src="@/assets/img/icons/ranking_points.png"
              alt="Classificação na Unidade"
            />
          </div>
          <h2 class="mb-0">{{ ranking.monthlyPoints }}</h2>
          <p class="text-muted">Pontos da Rodada</p>
        </div>
        <div class="col-6 d-flex flex-column">
          <div>
            <img
              class="img-rank"
              src="@/assets/img/icons/ranking_unity.png"
              alt="Classificação na Unidade"
            />
          </div>
          <h2 class="mb-0">{{ ranking.unityRankingPosition }}</h2>
          <p class="text-muted">Classificação na Unidade</p>
        </div>
        <!-- <div class="col-4 d-flex flex-column">
          <div>
            <img
              class="img-rank"
              src="@/assets/img/icons/ranking_general.png"
              alt="Classificação na Unidade"
            />
          </div>
          <h2 class="mb-0">{{ ranking.generalRankingPosition }}</h2>
          <p class="text-muted">Classificação Geral</p>
        </div> -->
      </div>
    </div>
  </div>
  <h3>PONTOS ACUMULADOS</h3>
  <h2 class="text-primary">{{ ranking.points }}</h2>
  <!-- <h3>CONQUISTAS</h3>
  <div>
    <span class="badge bg-secondary bg-opacity-20 text-secondary rounded-pill"
      ><i class="ph-star me-2"></i>PRÊMIOS</span
    >
    <span
      class="badge bg-secondary bg-opacity-20 ms-2 text-secondary rounded-pill"
      ><i class="ph-users me-2"></i>ESCOLHA DA GALERA</span
    >
  </div> -->
</template>

<script>
export default {
  name: 'Ranking',
  props: {
    ranking: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {}
  },
}
</script>
<style scoped>
.img-rank {
  height: 25px;
  margin-bottom: 10px;
}
</style>
