<template>
  <ProfileHeader title="CONQUISTAS" />
  <div class="text-center px-4 fw-light">
    <h4 class="fw-light text-warning">Minhas Conquistas!</h4>
    <h6 v-if="achievements.length === 0" class="fw-normal text-primary">
      Nenhuma conquista encontrada.
    </h6>
    <div v-else class="row">
      <div
        class="col-6 col-sm-4 col-md-3 col-lg-2"
        v-for="achievement in achievements"
        :key="achievement.id"
      >
        <img
          class="img-achievement"
          :src="require(`@/assets/img/achievement/${achievement.icon}`)"
          :alt="achievement.title"
          :title="achievement.title"
        />
        <h6 class="fw-light text-primary">{{ achievement.title }}</h6>
      </div>
    </div>
  </div>
</template>
<style>
.img-achievement {
  margin-left: auto;
  margin-right: auto;
  height: 100px;
}
</style>
<script>
import PATHS from '@/router/routesMap'
import ProfileHeader from '@/components/cards/ProfileHeader.vue'

export default {
  name: 'Achievements',
  data() {
    return {
      isLoading: false,
      achievements: [],
      apiPath: `${process.env.VUE_APP_BASE_URL_API}files/user_photo/`,
    }
  },
  components: {
    ProfileHeader,
  },
  methods: {
    getImgUrl(pet) {
      alert(`@/assets/img/achievement/${pet}`)
      // return require(`@/assets/img/achievement/${pet}`)
    },
    async getAchievements() {
      try {
        this.achievements = await this.$store.dispatch(
          'getAchievements',
          this.user,
        )
        this.isLoading = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.danger(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  mounted() {
    this.getAchievements()
  },
}
</script>
