<template>
  <UserHeader />
  <iframe
    src="https://gruposn.com.br/mestredovalor-regras/"
    frameborder="0"
    class="w-100 h-100"
  ></iframe>
</template>
<script>
import UserHeader from '@/components/cards/UserHeader.vue'

export default {
  name: 'Rules',
  data() {
    return {
      isLoading: true,
      quiz: [],
    }
  },
  components: {
    UserHeader,
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
}
</script>
