<template>
  <router-link v-if="quiz.hasPendence" :to="`/quiz/${quiz.uuid}`">
    <div class="card bg-primary">
      <div class="card-body">
        <div class="d-flex align-content-center align-items-center">
          <img
            src="@/assets/img/icons/ranking.png"
            class="img-avaliable me-2"
            alt=""
          />
          <div class="text-white">
            <h4 class="mb-1">Questionário Disponível</h4>
            <p>
              Você pode responder até {{ dateTimeSqlToDateTimeBR(quiz.endAt) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
  <div v-else class="card bg-primary">
    <div class="card-body">
      <div class="d-flex align-content-center align-items-center">
        <img
          src="@/assets/img/icons/ranking.png"
          class="img-avaliable me-2"
          alt=""
        />
        <div class="text-white">
          <h4 class="mb-1">Questionário Respondído</h4>
          <p>Você fez {{ quiz.points }} pontos</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeSqlToDateTimeBR } from '@/utils/date'

export default {
  name: 'AvaliableQuiz',
  props: {
    quiz: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dateTimeSqlToDateTimeBR,
    }
  },
}
</script>
<style scoped>
.img-avaliable {
  height: 40px;
}
</style>
