<template>
  <div id="bar-primary" class="bg-primary p-3">
    <div class="d-flex justify-content-between">
      <router-link class="text-light" to="home"
        ><i class="ph-caret-left"></i
      ></router-link>
      <h5 class="text-light">{{ title }}</h5>
      <div class="help"></div>
    </div>
  </div>
  <div class="d-flex">
    <img
      v-if="userPhoto"
      id="img-profile"
      :src="`${apiPath}${user.cpf}.jpg`"
      class="img-fluid text-center border-2 ${3|rounded-top,rounded-right,rounded-bottom,rounded-left,rounded-circle,|}"
      alt=""
    />
  </div>
  <div id="bar-transition" v-if="userPhoto" class="bg-primary"></div>
  <div id="bar-light" v-if="userPhoto" class="bg-light"></div>
  <div class="p-4 text-center">
    <h4 class="text-center">{{ user.name }}</h4>
    <h6 class="text-center text-muted">{{ user.filialName }}</h6>
  </div>
</template>
<script>
import PATHS from '@/router/routesMap'

export default {
  name: 'ProfileHeader',
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      apiPath: `${process.env.VUE_APP_BASE_URL_API}files/user_photo/`,
    }
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
    userPhoto() {
      return this.$store.getters.userPhoto
    },
  },
}
</script>
<style scoped>
#bar-primary {
  height: 200px;
}

#bar-transition {
  height: 40px;
  margin-top: -100px;
}

#bar-light {
  height: 100px;
  margin-top: -40px;
  border-radius: 30px;
}

#img-profile {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  margin-top: -100px;
  margin-left: auto;
  margin-right: auto;

  border: 0.7rem solid #d3d3d3;
}
</style>
